import React from 'react';

const NotFoundComponent: React.FC = () => {
  return (
    <div className="d-flex justify-content-center align-items-center not-found-container">
      <h1 className="text-center not-found-text">Nie znaleziono strony!</h1>
    </div>
  );
};

export default NotFoundComponent;
