import React from 'react';

const ContactComponent: React.FC = () => {
  return (
    <div className="container marketing" style={{ marginTop: '60px' }}>
      <div className="row featurette">
        <h1 className="display-4 fw-bold text-body-emphasis text-center">Kontakt</h1>
        <div className="col-lg-6 mx-auto">
          <ul className="d-flex flex-column align-items-center">
            <p className="lead zm">
              <li>Tel: <a href="tel:+48537745936">537 745 936</a></li>
            </p>
            <p className="lead zm">
              <li>E-Mail: <a href="mailto:kamilborowski@onet.eu">kamilborowski@onet.eu</a></li>
            </p>
            <p className="lead zm">
              <li>NIP: 6653067449</li>
            </p>
          </ul>
          <div className="ratio ratio-16x9">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6134.058080875044!2d18.212863!3d52.281333!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471b35007da75443%3A0x19cd7426907c86b4!2zV8WCYWR6aW1pcsOzdyA3OSwgNjItNTMwIFfFgmFkemltaXLDs3c!5e1!3m2!1spl!2spl!4v1712241540575!5m2!1spl!2spl" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
      <hr className="featurette-divider" />
    </div>
  );
};

export default ContactComponent;
