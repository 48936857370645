import React from 'react';

const LawContentComponent: React.FC = () => {
  return (
    <div className="container marketing" style={{ marginTop: '60px' }}>
      <div className="row featurette">
        <h1 className="display-4 fw-bold text-body-emphasis text-center mb-4">Treść Ustawy</h1>
        <div className="col-lg-6 mx-auto">
          <p className="lead">
            Zgodnie z zapisami Ustawy o bezpieczeństwie imprez masowych, organizator imprezy sportowej lub artystyczno-rozrywkowej ponosi pełną odpowiedzialność za właściwe zabezpieczenie medyczne uczestników imprezy masowej. Obowiązek zorganizowania pomocy medycznej spoczywa na organizatorze każdego wydarzenia sportowego lub artystyczno–rozrywkowego, w trakcie którego jest co najmniej:
          </p>
          <ul>
            <p className="lead">
              <li>300 miejsc dla uczestników i widzów imprezy sportowej wewnątrz budynku,</li>
            </p>
            <p className="lead">
              <li>500 miejsc dla uczestników imprezy artystyczno-rozrywkowej wewnątrz budynku</li>
            </p>
            <p className="lead">
              <li>1000 miejsc w przypadku każdej imprezy masowej odbywającej się na otwartym terenie/stadionie itp.</li>
            </p>
          </ul>
          <p className="lead">
            Zgonie z Rozporządzeniem Ministra Zdrowia z dnia 6 lutego 2012 r. w sprawie minimalnych wymagań dotyczących zabezpieczenia pod względem medycznym imprezy masowej każda impreza powinna mieć zabezpieczenie medyczne zależne od ilości uczestników.
          </p>
          <p className="lead">
            W przypadku każdej imprezy do 5000 uczestników minimalne zabezpieczenie medyczne stanowi jedna karetka podstawowa (z dwoma ratownikami medycznymi) oraz jeden patrol ratowniczy (dwóch ratowników). W przypadku imprezy podwyższonego ryzyka zabezpieczenie medyczne imprezy powinno składać się co najmniej z karetki z lekarzem oraz patrolu ratowniczego.
          </p>
          <p className="lead">
            Ze względu na indywidualny charakter każdej imprezy ceny oferowanych przez nas usług są każdorazowo negocjowane, w zależności od składu zespołu, miejsc i czasu trwania zabezpieczenia.
          </p>
        </div>
      </div>
      <hr className="featurette-divider" />
    </div>
  );
};

export default LawContentComponent;
