import React from 'react';
import photo1 from '../assets/img/1_1_1.png';
import photo2 from '../assets/img/2_2_2.png';
import photo3 from '../assets/img/3_3_3.png';
import photo4 from '../assets/img/4.png';
import photo5 from '../assets/img/5.png';
import photo6 from '../assets/img/6.png';

const MedicalSecurityComponent: React.FC = () => {
  return (
    <div className="container marketing" style={{ marginTop: '60px' }}>
      <div className="row featurette">
        <h1 className="display-4 fw-bold text-body-emphasis text-center">Zabezpieczenia Medyczne</h1>
        <div className="col-lg-6 mx-auto">
          <ul className="d-flex flex-column align-items-center">
            <p className="lead zm">
              <li>Mecze drużynowe</li>
            </p>
            <p className="lead zm">
              <li>Eventy</li>
            </p>
            <p className="lead zm">
              <li>Imprezy firmowe</li>
            </p>
            <p className="lead zm">
              <li>Koncerty</li>
            </p>
            <p className="lead zm">
              <li>Turnieje</li>
            </p>
            <p className="lead zm">
              <li>Imprezy kulturowe</li>
            </p>
            <p className="lead zm">
              <li>Biegi</li>
            </p>
            <p className="lead zm">
              <li>Zawody sportowe</li>
            </p>
          </ul>
        </div>
        <h1 className="display-6 fw-bold text-body-emphasis text-center">W swojej ofercie posiadamy</h1>
        <div className="col-lg-6 mx-auto">
          <ul className="d-flex flex-column align-items-center">
            <p className="lead zm">
              <li>Quad ratowniczy z torba PSP R1 +AED</li>
            </p>
            <p className="lead zm">
              <li>Karetkę typu P (2 ratowników)</li>
            </p>
            <p className="lead zm">
              <li>Karetkę typu S (lekarz, ratownik medyczny, pielęgniarka)</li>
            </p>
            <p className="lead zm">
              <li>Patrol ratowniczy z plecakiem PSP R1 +AED</li>
            </p>
            <p className="lead zm">
              <li>Punkt medyczny (ratownik medyczny, pielęgniarka oraz ratownik KPP)</li>
            </p>
            <p className="lead zm">
              <li>Ratownik KPP z torba PSP R1 oraz AED</li>
            </p>
          </ul>
          <p className="lead text-center">
            Usługi wyceniane są indywidualnie z możliwością negocjacji ceny przy dużych imprezach oraz stałej współpracy zapraszamy do <a href="/kontakt">kontaktu</a>.
          </p>
        </div>
      </div>
      <div className="text-center">
        <img src={photo1} className="rounded float-left" style={{ width: '350px' }} alt="1" />
        <img src={photo2} className="rounded float-center" style={{ width: '350px' }} alt="2" />
        <img src={photo3} className="rounded float-right" style={{ width: '350px' }} alt="3" />
      </div>
      <div className="text-center">
        <img src={photo4} className="rounded float-left" style={{ width: '350px' }} alt="1" />
        <img src={photo5} className="rounded float-center" style={{ width: '350px' }} alt="2" />
        <img src={photo6} className="rounded float-right" style={{ width: '350px' }} alt="3" />
      </div>
      <hr className="featurette-divider" />
    </div>
  );
};

export default MedicalSecurityComponent;
