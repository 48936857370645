import React from 'react';
import firstSlide from '../assets/img/firstslide.jpg';
import secondSlide from '../assets/img/secondslide.jpg';
import lastSlide from '../assets/img/lastslide.jpg';
import boromed from '../assets/img/boromed.png';

const Feature: React.FC<{ icon: string, title: string, description: string, link: string }> = ({ icon, title, description, link }) => {
    return (
        <div className="feature col">
            <div className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                <i className={icon}></i>
            </div>
            <h3 className="fs-2 text-body-emphasis">{title}</h3>
            <p>{description}</p>
            <a href={link} className="icon-link">Zobacz więcej <svg className="bi"><use xlinkHref="#chevron-right" /></svg></a>
        </div>
    );
};

const CarouselComponent: React.FC = () => {
    return (
        <>
            <div id="myCarousel" className="carousel slide mb-6" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button className="circle active" type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1"></button>
                    <button className="circle" type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button className="circle" type="button" data-bs-target="#myCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img className="d-block w-100 img-cover" src={firstSlide} alt="First slide" />
                    </div>
                    <div className="carousel-item">
                        <img className="d-block w-100 img-cover" src={secondSlide} alt="Second slide" />
                    </div>
                    <div className="carousel-item">
                        <img className="d-block w-100 img-cover" src={lastSlide} alt="Last slide" />
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>

            <div className="container marketing">
                <div className="row featurette">
                    <div className="col-md-7 order-md-2">
                        <h2 className="featurette-heading fw-normal lh-1">Transport Sanitarny <span className="text-body-secondary">BOROMED</span></h2>
                        <p className="lead">Zapewniamy transport osób do placówek medycznych i innych miejsc. Dysponujemy w pełni wyposażonymi karetkami i wykwalifikowanym personelem, w tym ratownikami KPP, którzy mogą udzielić pierwszej pomocy na miejscu. Nasze usługi gwarantują bezpieczeństwo i profesjonalizm na najwyższym poziomie.</p>
                    </div>
                    <div className="col-md-5 order-md-1 d-flex justify-content-center flex-column">
                        <img src={boromed} alt="" className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" />
                    </div>
                </div>

                <hr className="featurette-divider" />

                <div className="container px-4" id="featured-3">
                    <div className="row g-4 row-cols-1 row-cols-lg-3">
                        <Feature
                            icon="fa-solid fa-hand-holding-droplet"
                            title="Transport Sanitarny"
                            description="Zapewniamy transport osób do placówek medycznych i innych miejsc. Dysponujemy w pełni wyposażonymi karetkami i wykwalifikowanym personelem, w tym ratownikami KPP, którzy mogą udzielić pierwszej pomocy na miejscu."
                            link="/transport/sanitarny"
                        />
                        <Feature
                            icon="fa-solid fa-truck-medical"
                            title="Transport Medyczny"
                            description="Zapewniamy transport medyczny dla osób potrzebujących opieki w czasie podróży. Mamy zarówno karetki podstawowe, jak i specjalistyczne, wyposażone w sprzęt medyczny. Nasz zespół to lekarze, ratownicy medyczni i pielęgniarki."
                            link="/transport/medyczny"
                        />
                        <Feature
                            icon="fa-solid fa-kit-medical"
                            title="Zabezpieczenia Medyczne"
                            description="Zapewniamy medyczne zabezpieczenia na różne wydarzenia, w tym mecze, eventy, koncerty czy zawody sportowe. Mamy quady ratownicze z AED, karetki typu P i S oraz punkt medyczny z ratownikami KPP i pielęgniarką."
                            link="/zabezpieczenia-medyczne"
                        />
                    </div>
                </div>

                <hr className="featurette-divider" />
            </div>
        </>
    );
};

export default CarouselComponent;
