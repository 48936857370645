import React from 'react';
import photo1 from '../assets/img/1_1.png';
import photo2 from '../assets/img/2_2.png';
import photo3 from '../assets/img/3_3.png';

const MedicalTransportComponent: React.FC = () => {
  return (
    <div className="container marketing" style={{ marginTop: '60px' }}>
      <div className="row featurette">
        <h1 className="display-4 fw-bold text-body-emphasis text-center">Transport Medyczny</h1>
        <div className="col-lg-6 mx-auto">
          <p className="lead mb-4 text-center">
            Zajmujemy się transportem osób potrzebujących opieki medycznej w czasie przejazdu. Dysponujemy karetkami podstawowymi i specjalistycznymi. Oferujemy usługi transportu medycznego komfortowymi karetkami z pełnym wyposażeniem medycznym. Zapewniamy wykwalifikowany personel medyczny (lekarzy, ratowników medycznych, pielęgniarki)
            <p className="lead mb-0 text-center">Karetki typu podstawowego</p>
            <p className="lead mb-4 text-center">(2 ratowników medycznych)</p>
            <p className="lead mb-0 text-center">Karetka typu specjalistycznego</p>
            <p className="lead mb-4 text-center">(lekarz, ratownik medyczny, pielęgniarka i kierowca)</p>
            <p className="lead mb-4 text-center">Świadczymy usługi na terenie całego kraju 24/7</p>
          </p>
        </div>
      </div>
      <div className="text-center">
        <img src={photo1} className="rounded float-left" style={{ width: '350px' }} alt="1" />
        <img src={photo2} className="rounded float-center" style={{ width: '350px' }} alt="2" />
        <img src={photo3} className="rounded float-right" style={{ width: '350px' }} alt="3" />
      </div>
      <hr className="featurette-divider" />
    </div>
  );
};

export default MedicalTransportComponent;
