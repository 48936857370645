import React from 'react';
import aboutUs from '../assets/img/onas.png';

const AboutUsComponent: React.FC = () => {
  return (
    <div className="container marketing" style={{ marginTop: '60px' }}>
      <div className="row featurette">
        <div className="col-md-7 order-md-2 d-flex justify-content-center flex-column">
          <h2 className="featurette-heading fw-normal lh-1" style={{ marginTop: '0' }}>O nas</h2>
          <p className="lead">
            BoroMed to firma, która jest zaangażowana w zapewnienie kompleksowych usług związanych z bezpieczeństwem medycznym podczas różnego rodzaju wydarzeń. Ich działania obejmują przygotowanie i realizację planów bezpieczeństwa medycznego na imprezach sportowych, koncertach, festiwalach, targach i innych wydarzeniach masowych.
          </p>
          <p className="lead">
            Firma oferuje szeroki zakres usług, w tym:
          </p>
          <p className="lead">
            Zabezpieczenia medyczne: BoroMed zapewnia wykwalifikowany personel medyczny oraz niezbędny sprzęt medyczny do szybkiego reagowania na wszelkie nagłe przypadki podczas imprez.
          </p>
          <p className="lead">
            Transport sanitarny i medyczny: Dysponują flotą pojazdów wyposażonych w niezbędny sprzęt medyczny, umożliwiając szybki i bezpieczny transport pacjentów do szpitali lub innych placówek medycznych w razie potrzeby.
          </p>
          <p className="lead">
            Pokazy pierwszej pomocy: BoroMed organizuje także szkolenia i pokazy pierwszej pomocy, które mogą być częścią programu imprez, edukując uczestników na temat podstawowych technik udzielania pomocy przedmedycznej.
          </p>
          <p className="lead">
            Dzięki swojemu doświadczeniu i wyszkolonemu personelowi, BoroMed zapewnia klientom poczucie bezpieczeństwa oraz profesjonalną opiekę medyczną podczas różnego rodzaju wydarzeń, co jest kluczowe dla udanej realizacji imprez masowych.
          </p>
        </div>
        <div className="col-md-5 order-md-1 d-flex justify-content-center flex-column">
          <img src={aboutUs} alt="" className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" />
        </div>
      </div>
      <hr className="featurette-divider" />
    </div>
  );
};

export default AboutUsComponent;
