import React from 'react';
import nfz from '../assets/img/nfz.webp'

const SanitaryTransportWithinNFZComponent: React.FC = () => {
    return (
        <div className="container marketing" style={{ marginTop: '60px' }}>
            <div className="row featurette">
                <div className="col-12 text-center">
                    <img
                        src={nfz}
                        alt="Logo NFZ"
                        className="mb-4"
                        style={{ width: '120px' }}
                    />
                </div>
                <h1 className="display-4 fw-bold text-body-emphasis text-center">
                    Zasady realizacji transportów sanitarnych w ramach NFZ
                </h1>
                <div className="col-lg-10 mx-auto">
                    <p className="lead mb-4">
                        Transporty sanitarne stanowią jedną z kluczowych usług, które oferujemy w ramach współpracy z przychodniami POZ (lekarzami rodzinnymi) finansowanych przez Narodowy Fundusz Zdrowia (NFZ). Wiele osób nie jest świadomych, że mają prawo do takiego transportu, a także nie wiedzą, jakie sytuacje go obejmują. Poniżej przedstawiamy zasady oraz szczegóły dotyczące realizacji transportów sanitarnych:
                    </p>
                    <h3>Podstawa prawna do transportu sanitarnego:</h3>
                    <p className="mb-4">
                        Transport sanitarny jest realizowany zgodnie z art. 41 ust. 1 i 2 ustawy z dnia 27 sierpnia 2004 roku o świadczeniach opieki zdrowotnej finansowanych ze środków publicznych, który stanowi:
                    </p>
                    <blockquote className="blockquote mb-4">
                        Świadczeniobiorcy, na podstawie zlecenia lekarza lub felczera ubezpieczenia zdrowotnego, mają prawo do bezpłatnego przejazdu środkami transportu sanitarnego, w tym transportem lotniczym, do najbliższego podmiotu leczniczego, udzielającego odpowiednich świadczeń zdrowotnych, oraz z powrotem, w przypadkach:
                        <ul>
                            <li>potrzeby natychmiastowego leczenia w podmiocie leczniczym,</li>
                            <li>konieczności kontynuowania leczenia.</li>
                        </ul>
                    </blockquote>
                    <h3>Transport sanitarny w POZ</h3>
                    <p>
                        Usługi transportu sanitarnego w ramach podstawowej opieki zdrowotnej (POZ) realizowane są od poniedziałku do piątku w godzinach 8:00–18:00, z wyjątkiem dni ustawowo wolnych.
                    </p>
                    <p>Transport sanitarny odbywa się na zlecenie lekarza POZ i obejmuje przewóz świadczeniobiorcy:</p>
                    <ul>
                        <li>
                            z miejsca zamieszkania do placówki leczniczej w przypadku leczenia stacjonarnego lub dziennego,
                        </li>
                        <li>
                            z miejsca zamieszkania do placówki, w której odbywa się pierwsza wizyta w poradni specjalistycznej lub leczenie stomatologiczne i z powrotem,
                        </li>
                        <li>
                            celem wykonania zabiegów medycznych związanych z procesem leczenia przez lekarza POZ,
                        </li>
                        <li>do zakładu długoterminowej opieki zdrowotnej.</li>
                    </ul>
                    <p className="mb-4">
                        W przypadku, gdy wskazana placówka nie jest najbliższą możliwą opcją medyczną, pacjent pokrywa różnicę w kosztach transportu, wynikającą z odległości między najbliższym a wybranym świadczeniodawcą.
                    </p>
                    <h3>Przykłady transportów sanitarnych</h3>
                    <ul>
                        <li>
                            <strong>Transport sanitarny w celu zachowania ciągłości leczenia:</strong> Skierowanie pacjenta z dysfunkcją narządu ruchu do specjalisty powinno wiązać się ze zleceniem transportu sanitarnego przez lekarza POZ.
                        </li>
                        <li>
                            <strong>Transport sanitarny do poradni, do której nie jest wymagane skierowanie lekarza:</strong> Zlecenie transportu na pierwszą wizytę wystawia lekarz POZ.
                        </li>
                        <li>
                            <strong>Transport po hospitalizacji:</strong> W przypadku, gdy pacjent zostaje wypisany ze szpitala i potrzebuje dalszego transportu do innej placówki lub wraca do domu, zlecenie transportu wystawia lekarz wypisujący pacjenta.
                        </li>
                        <li>
                            <strong>Transport sanitarny w przypadku chorób przewlekłych i specjalistycznych:</strong> Transport w takich przypadkach może być częściowo refundowany w wysokości 40%.
                        </li>
                    </ul>
                    <h3>Podsumowanie</h3>
                    <p>
                        Transporty sanitarne w ramach NFZ są usługą przysługującą pacjentom, którzy wymagają przewozu do placówek medycznych w określonych przypadkach. Usługi te są dostępne na zlecenie lekarza POZ, a w przypadku spełnienia odpowiednich warunków, są one realizowane bezpłatnie lub odpłatnie, w zależności od sytuacji pacjenta.
                    </p>
                </div>
            </div>
            <hr className="featurette-divider" />
        </div>
    );
};

export default SanitaryTransportWithinNFZComponent;
