import React from 'react';
import photo1 from '../assets/img/1.png';
import photo2 from '../assets/img/2.png';
import photo3 from '../assets/img/3.png';

const SanitaryTransportComponent: React.FC = () => {
  return (
    <div className="container marketing" style={{ marginTop: '60px' }}>
      <div className="row featurette">
        <h1 className="display-4 fw-bold text-body-emphasis text-center">Transport Sanitarny</h1>
        <div className="col-lg-6 mx-auto">
          <p className="lead mb-4 text-center">
            Zajmujemy się transportem osób potrzebujących transportu do placówek medycznych i nie tylko. Dysponujemy karetkami transportowymi. Oferujemy usługi transportu sanitarnego komfortowymi karetkami z pełnym wyposażeniem niezbędnym do podjęcia natychmiastowej kwalifikowanej pierwszej pomocy. Zapewniamy wykwalifikowany personel składający się z ratowników KPP
            <p className="lead mb-0 text-center">Karetka typu transportowego</p>
            <p className="lead mb-4 text-center">(2 ratowników kwalifikowanej pierwszej pomocy)</p>
          </p>
        </div>
      </div>
      <div className="text-center">
        <img src={photo1} className="rounded float-left" style={{ width: '350px' }} alt="1" />
        <img src={photo2} className="rounded float-center" style={{ width: '350px' }} alt="2" />
        <img src={photo3} className="rounded float-right" style={{ width: '350px' }} alt="3" />
      </div>
      <hr className="featurette-divider" />
    </div>
  );
};

export default SanitaryTransportComponent;
